export const checkLogin = 'auth/login';
export const register = 'auth/register';
export const LogoutUser = 'auth/logout';
export const ForgotPassword = 'auth/forgot-password';
export const ResetPassword = 'auth/reset-password';

// ==================== Profile ==================== //
export const ChangePassword = 'profile/change-password';
export const ProfileUpdate = 'user/profile';
export const DocumentList = 'user/document-list';
export const AddDocument = 'user/upload-document';
export const DeleteDocument = 'user/delete-document';

// ==================== Role ==================== //
export const RoleList = 'authentication/role-list';
export const AddRole = 'authentication/role-create';
export const EditRole = 'authentication/role-update';
export const DeleteRole = 'authentication/role-delete';

// ==================== Permission & Group ==================== //
export const PermissionList = 'authentication/permission/list';

export const PermissionGroupList = 'authentication/permission/group-list';
export const AddPermissionGroup = 'authentication/permission/group-create';
export const UpdatePermissionGroup = 'authentication/permission/group-update';
export const DeletePermissionGroup = 'authentication/permission/group-delete';

// ==================== Restrictions ==================== //
export const RestrictionList = 'authentication/restriction/list';
export const AddRestriction = 'authentication/restriction/create';
export const EditRestriction = 'authentication/restriction/update';
export const DeleteRestriction = 'authentication/restriction/delete';

// ==================== User ==================== //
export const UserList = 'user/list';
export const AddUser = 'user/create';
export const EditUser = 'user/update';
export const DeleteUser = 'user/delete';
export const ViewUser = 'user/view';
export const DeleteUserDoc = 'user/delete-document';
export const RoleUser = 'user/role-users';
export const ExportUser = 'user/export';
export const ImportUser = 'user/import';
export const SaveImportUser = 'user/save-import';

// ==================== Status ==================== //
export const StatusList = 'status-master/list';
export const AddStatus = 'status-master/create';
export const EditStatus = 'status-master/update';
export const DeleteStatus = 'status-master/delete';

// ==================== Dashboard ==================== //
export const dashboard = 'dashboard';
export const CompanyMonthlyRevenue = 'dashboard/monthly-revenue-list';

// ==================== Employer ==================== //
export const EmployerList = 'employer/list';
export const AddEmployer = 'employer/create';
export const EditEmployer = 'employer/update';
export const DeleteEmployer = 'employer/delete';
export const AddEmployerUser = 'employer/add-user';
export const DownloadEmployerDoc = 'employer/download-document';
export const DeleteEmployerDoc = 'employer/delete-document';

// ==================== Job ==================== //
export const JobList = 'job/list';
export const AddJob = 'job/create';
export const EditJob = 'job/update';
export const DeleteJob = 'job/delete';
export const ViewJob = 'job/view';
export const UpdateJobAssign = 'job/update-assign';
export const JobStateList = 'location/get-state';
export const JobCityList = 'location/get-city';
// Job Candidates
export const JobCandidatesList = 'job/job-candidates-list';
export const CandidatesForJob = 'job/job-candidates';
export const AddRemoveJobCandidate = 'job/add-remove-job-candidates';
export const UpdateJobCandidate = 'job/update-job-candidates';

// ==================== Location ==================== //
export const CountryList = 'location/country-list';
export const StateList = 'location/state-list';
export const ChangeStatus = 'location/change-status';
export const CityList = 'location/city-list';
export const AddCity = 'location/create-city';
export const EditCity = 'location/update-city';
export const AddEditCity = 'location/add-edit-city';
export const DeleteCity = 'location/delete-city';

// ==================== Industry ==================== //
export const IndustryList = 'industry/list';
export const AddIndustry = 'industry/create';
export const EditIndustry = 'industry/update';
export const DeleteIndustry = 'industry/delete';

// ==================== Job Title ==================== //
export const JobTitleList = 'job-title/list';
export const AddJobTitle = 'job-title/create';
export const EditJobTitle = 'job-title/update';
export const DeleteJobTitle = 'job-title/delete';

export const AddJobTitleFromJob = 'job-title/add-title-job';
// ==================== Job Type ==================== //
export const JobTypeList = 'job-type/list';
export const AddJobType = 'job-type/create';
export const EditJobType = 'job-type/update';
export const DeleteJobType = 'job-type/delete';

// ==================== Key Skill ==================== //
export const KeySkillList = 'key-skill/list';
export const AddKeySkill = 'key-skill/create';
export const EditKeySkill = 'key-skill/update';
export const DeleteKeySkill = 'key-skill/delete';

export const AddKeySkillFromJob = 'key-skill/add-from-job';

// ==================== Institute ==================== //
export const InstituteList = 'institute/list';
export const AddInstitute = 'institute/create';
export const EditInstitute = 'institute/update';
export const DeleteInstitute = 'institute/delete';

export const AddInstituteFromCandidate = 'institute/add-from-candidate';
// ==================== Functional Area ==================== //
export const FunctionalAreaList = 'functional-area/list';
export const AddFunctionalArea = 'functional-area/create';
export const EditFunctionalArea = 'functional-area/update';
export const DeleteFunctionalArea = 'functional-area/delete';

export const AddFunctionalAreaFromJob = 'functional-area/add-from-job';
// ==================== Shift Timing ==================== //
export const ShiftTimingList = 'shift-timing/list';
export const AddShiftTiming = 'shift-timing/create';
export const EditShiftTiming = 'shift-timing/update';
export const DeleteShiftTiming = 'shift-timing/delete';

// ==================== Education ==================== //
export const EducationList = 'education/list';
export const AddEducation = 'education/create';
export const EditEducation = 'education/update';
export const DeleteEducation = 'education/delete';

// ==================== Candidate ==================== //
export const CandidateList = 'candidate/list';
export const CourseList = 'candidate/course-list';
export const AddCandidate = 'candidate/create';
export const EditCandidate = 'candidate/update';
export const DeleteCandidate = 'candidate/delete';
export const CandidateAddData = 'candidate/add';
export const CandidateEditData = 'candidate/edit';

// ================== Role Level ==================== //
export const RoleLevelList = 'authentication/role-level/list';
export const DeleteRoleLevel = 'authentication/role-level/delete';
export const AddRoleLevel = 'authentication/role-level/create';
export const EditRoleLevel = 'authentication/role-level/update';

// ================== Notification ==================== //
export const NotificationList = 'notification/list';

// ================== Reports ==================== //
export const OrganizationChart = 'report/organization-chart';
